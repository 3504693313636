import React from 'react';
import { Calendar } from '../../icons';

// TODO: Update the prop types, facing some import issues
// type TCustomCalendarIcon = {
// 	className: string;
// 	onClick: VoidFunction;
// };

export const CustomCalendarIcon = (props: any) => {
	return (
		<div
			{...props}
			tabIndex={0}
			onKeyDown={e => {
				if (e.key === ' ' || e.key === 'ArrowDown') {
					e.currentTarget.click();
				}
			}}
			aria-label="Calendar Icon, Press ArrowDown key to open calendar and select the date"
		>
			<Calendar width="20px" height="20px" />
		</div>
	);
};
