/* eslint-disable max-lines */
import React, { useState } from 'react';
import classes from './StepFour.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { StyledLink } from '../../common/Link';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../types/signup.types';
import { Loader } from '../../../components/common/Loader';
import { useForm, Controller } from 'react-hook-form';
import {
	CognitoUserAttribute,
	CognitoUser,
	AuthenticationDetails
} from 'amazon-cognito-identity-js';
import { dateUtil } from '../../../utils/dateUtil';
import UserPool from '../../../utils/UserPool';
import { Checkbox } from '../../common/Checkbox';
import { Banner, BannerTypes } from '../../common/Banner';
import { Warning } from '../../icons';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { signupApi } from '../../../services/signupApi';
import { DEFAULT_PHONE_NUMBER } from '../../../constants/common';
import * as braze from '@braze/web-sdk';
import noteSvg from '../../../assets/img/note.svg';
import { StyledLinkTypes } from '../../common/Link/StyledLink.constants';

type StepProps = {
	onNextStep: () => void;
	onPreviousStep: () => void;
	userData: UserData;
	setUserData: (data: UserData) => void;
};

type FormValues = {
	eConsent: boolean;
	agreement: boolean;
	policy: boolean;
	fsa: boolean;
};

export const StepFour = ({ userData }: StepProps) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [awsSignupError, setAwsSignupError] = useState({ message: '' });
	const {
		handleSubmit,
		control,
		formState: { isValid }
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: {
			fsa: false,
			eConsent: false,
			agreement: false,
			policy: false
		}
	});

	// useEffect(() => {
	// 	(window as any).gtag('event', 'page_view', {
	// 		app_name: 'Zenda-web',
	// 		page_title: 'Onboarding_activate_card',
	// 		// screen_name: 'Onboarding_activate_card',
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 	});
	// }, []);

	/**
	 * Call the Pre-signup of api for the users who are doing login first time
	 * @param patchData :Object
	 */
	const doPreSignInOfUser = (patchData: any) => {
		signupApi
			.firstLogin(patchData)
			.then(() => {
				// Remove `presignupauth` from localstorage as it not necessary for upcoming api call
				localStorageUtil.removeToken('presignupauth');
				signupApi
					.fetchGaTags()
					.then(response => {
						braze.changeUser(response.data.data.braze_id);
						braze.logCustomEvent('login_completion');
						setTimeout(() => {
							// Take User to next step
							setIsLoading(false);
							navigate('/apps/dashboard', { replace: true });
						}, 200);
					})
					.catch(() => {});
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	/**
	 * Call the aws incognito api to authenticate the user and get the all tokens
	 * @param email :string
	 * @param password : string
	 */
	const authenticateUser = (email: string, password: string) => {
		const user = new CognitoUser({
			Username: userData.email.toLowerCase().trim(),
			Pool: UserPool
		});

		const authDetails = new AuthenticationDetails({
			Username: userData.email?.toLowerCase().trim(),
			Password: password?.trim()
		});

		user.authenticateUser(authDetails, {
			onSuccess: result => {
				const patchData = {
					work_email: userData.email?.toLowerCase().trim(),
					sent_at: dateUtil.getEpochTime()
				};

				// Set all the necessary tokens into the localstorage
				localStorageUtil.setToken({
					accessToken: result.getAccessToken().getJwtToken(),
					refreshToken: result.getRefreshToken().getToken(),
					idToken: result.getIdToken().getJwtToken()
				});

				// Call the pre-sign backend API
				doPreSignInOfUser(patchData);
			},
			onFailure: () => {
				setIsLoading(false);
			}
		});
	};

	const onSubmit = handleSubmit(async () => {
		const attributeList = [];
		if (isLoading) {
			return;
		}
		const dataEmail = {
			Name: 'email',
			Value: userData.email?.toLowerCase().trim()
		};
		const dataPhoneNumber = {
			Name: 'phone_number',
			Value: DEFAULT_PHONE_NUMBER
		};

		// Added custom attribute
		const dataUserToken = {
			Name: 'custom:user_token',
			Value: userData.user_token
		};

		const attributeEmail = new CognitoUserAttribute(dataEmail);
		const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
		const attributeUserToken = new CognitoUserAttribute(dataUserToken);

		attributeList.push(attributeEmail);
		attributeList.push(attributePhoneNumber);
		attributeList.push(attributeUserToken);

		setIsLoading(true);
		UserPool.signUp(
			userData.email?.toLowerCase().trim(),
			userData.password?.trim(),
			attributeList,
			[],
			error => {
				if (error) {
					setAwsSignupError({ message: error.message });
				} else {
					// authenticate the User
					authenticateUser(userData.email?.toLowerCase().trim(), userData.password?.trim());
				}
			}
		);
	});

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="pt-32">
			<div className={classes.header}>
				<img src={noteSvg} width="200px" alt=""></img>
				<h2 className={`text-h2-325 mt-48 ${classes.title}`}>One final step!</h2>
			</div>
			<div className={classes.card}>
				{awsSignupError && awsSignupError.message && (
					<span className={classes.banner}>
						<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
							{awsSignupError.message}
						</Banner>
					</span>
				)}

				<form onSubmit={onSubmit} className={classes.form} autoComplete="off">
					<p className={`pb-48 ${classes.info}`}>
						By clicking Create Login, you agree to, and have read:
					</p>

					<div className={classes['check-box']}>
						{userData?.is_hsa && (
							<div className={classes['check-box-text']}>
								<Controller
									control={control}
									name="eConsent"
									rules={{ required: true }}
									render={({ field: { onChange, value }, fieldState: {} }) => (
										<div className={`d-flex align-items-start ${classes['text-left']}`}>
											<Checkbox checked={value} onChange={onChange}>
												<span className={`text-base-325 ${classes['text-left']}`}>
													InComm Benefits'{' '}
												</span>
												<span className={classes['link-position']}>
													<StyledLink
														href="https://incommbenefits.com/e-sign-consent/"
														type={StyledLinkTypes.TERTIARY}
													>
														E-Sign Consent Disclosure
													</StyledLink>
												</span>
											</Checkbox>
											&nbsp;
										</div>
									)}
								/>
							</div>
						)}
						{(userData?.is_hsa || userData?.is_fsa || userData?.is_dcfsa) && (
							<div className={classes['check-box-text']}>
								<Controller
									control={control}
									name="agreement"
									rules={{ required: true }}
									render={({ field: { onChange, value }, fieldState: {} }) => (
										<div className={`d-flex align-items-start ${classes['text-left']}`}>
											<Checkbox checked={value} onChange={onChange}>
												<span className={`text-base-325 ${classes['text-left']}`}>
													InComm Benefits'{' '}
												</span>
												&nbsp;
												<span className={`${classes['link-position']}`}>
													<StyledLink
														href="https://incommbenefits.com/privacy/"
														type={StyledLinkTypes.TERTIARY}
													>
														Privacy Policy
													</StyledLink>
													<span>
														&nbsp; <span className="text-white text-base-325">and</span> &nbsp;
													</span>
													<StyledLink
														href="https://incommbenefits.com/terms/"
														type={StyledLinkTypes.TERTIARY}
													>
														User Agreement
													</StyledLink>
												</span>
											</Checkbox>
										</div>
									)}
								/>
							</div>
						)}
						{(userData?.is_hsa || userData?.is_fsa || userData?.is_dcfsa) && (
							<div className={classes['check-box-text']}>
								<Controller
									control={control}
									name="policy"
									rules={{ required: true }}
									render={({ field: { onChange, value }, fieldState: {} }) => (
										<div className="d-flex align-items-start">
											<Checkbox checked={value} onChange={onChange}>
												<p className={`text-base-325 ${classes['text-left']}`}>
													Coastal Community Bank's&nbsp;
													<span className={classes['link-position']}>
														<StyledLink
															href="https://www.coastalbank.com/privacy-center/"
															type={StyledLinkTypes.TERTIARY}
														>
															Privacy Policy
														</StyledLink>

														{userData?.is_hsa && (
															<>
																<span>&nbsp;&&nbsp;</span>
																<StyledLink
																	href="https://incommbenefits.com/bankagreement"
																	type={StyledLinkTypes.TERTIARY}
																>
																	HSA Agreement
																</StyledLink>
															</>
														)}
														{userData?.is_fsa && !userData?.is_hsa && (
															<>
																<span>&nbsp;&&nbsp;</span>
																<StyledLink
																	href="https://incommbenefits.com/cardholder"
																	type={StyledLinkTypes.TERTIARY}
																>
																	Card Holder Agreement
																</StyledLink>
															</>
														)}
													</span>
												</p>
											</Checkbox>
										</div>
									)}
								/>
							</div>
						)}
						{userData?.is_fsa && (
							<div className={classes['check-box-text']}>
								<Controller
									control={control}
									name="fsa"
									rules={{ required: true }}
									render={({ field: { onChange, value }, fieldState: {} }) => (
										<div className="d-flex ">
											<Checkbox checked={value} onChange={onChange}>
												<p className={`text-base-325 ${classes['text-left']}`}>
													I certify my InComm Benefits card will be used only for qualified expenses
													as described in my FSA/HRA plan. I acknowledge that this card cannot be
													used at all merchants that accept Visa debit cards.
												</p>
											</Checkbox>
										</div>
									)}
								/>
							</div>
						)}
					</div>
					<div className={`d-flex flex-column gap-20 mt-32`}>
						<Button type={ButtonTypes.TERTIARY} isDisabled={!isValid}>
							Create Login
						</Button>
					</div>
				</form>
				<p className="text-base-325 p-16 mt-16">
					<span className={classes['lost-text']}>Lost access to email?</span>
					<StyledLink href="mailto:support@incommbenefits.com" type={StyledLinkTypes.TERTIARY}>
						Contact Us
					</StyledLink>
				</p>
			</div>
		</div>
	);
};
