import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledLink } from '../../components/common/Link';
import { StyledLinkTypes } from '../../components/common/Link/StyledLink.constants';
import Select, { components } from 'react-select';
import {
	AccountType,
	ReimburseTo,
	getSelectStyle,
	getSingleValueStyle
} from '../../constants/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonSizes, ButtonTypes } from '../../components/common/Button';
import classes from './FixMistakenWithdrawalPage.module.scss';
import { Loader } from '../../components/common/Loader';
import { expenseApi } from '../../services/expenseService';
import { dashboardApi } from '../../services/dashboardService';
import { Banner, BannerTypes } from '../../components/common/Banner';
import { Incomplete, Warning } from '../../components/icons';
import Modal from 'react-modal';
import { MakeTransfer } from '../../components/MakeTransfer';
import { usePlaid } from '../../hooks/usePlaid';
import linkBankAccountError from '../../assets/img/error.svg';
import ConfirmationImage from '../../assets/img/ThumbsUpLight.svg';
import { ContactUs } from '../../components/ContactUs';
import { transactionsApi } from '../../services/transactionsService';
import FixWithdrawalSubmissionComplete from './FixWithdrawalSubmissionComplete';
import { getDecimalVal } from '../../utils/numberUtil';
import { customModalStyles } from './FixMistakenWithdrawal.utils';
import { IMistakenDist, IOption } from './FixMistakenWithdrawal.types';
import { plaidApi } from '../../services/plaidService';

const CustomOption = (props: any) => {
	return (
		<components.Option {...props}>
			<div>
				<span>{props.data.label}</span>
			</div>
		</components.Option>
	);
};
export const FixMistakenWithdrawalPage = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { openPlaid, networkLoading, networkResponse, networkError } = usePlaid('bank-account');
	const [isLoading, setIsLoading] = useState(false);
	// State to store the amount and taxYear for the mistaken distribution
	const [mistakenDist, setMistakenDist] = useState<IMistakenDist>({
		amount: '',
		taxYear: ''
	});
	// Everyday balance, ext_account_status, last four digits of ext
	const [extAccountDetails, setExtAccountDetails] = useState({
		status: false,
		accountId: '',
		accountNo: '',
		bankName: ''
	});
	// State fot the everyday balance
	const [everydayInfo, setEverydayInfo] = useState('');
	const [selectedAccount, setSelectedAccount] = useState<IOption>();
	const [modalVisible, setModalVisible] = useState({
		makeTransferModal: false,
		invalidAccountModal: false,
		contactUsModal: false,
		accountLinkSuccessModal: false
	});
	const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);
	// State to check if the account link interaction is happening through the fund everyday or directly from Link button
	const [linkThroughEveryday, setLinkThroughEveryday] = useState(false);

	const openModal = (modalName: string) => {
		setModalVisible(prevState => ({ ...prevState, [modalName]: true }));
	};

	const closeModal = (modalName: string) => {
		setModalVisible(prevState => ({ ...prevState, [modalName]: false }));
	};
	const accountOptions: IOption[] = useMemo(
		() => [
			{
				label: (
					<div className="d-flex justify-content-between">
						<div>
							{extAccountDetails.status ? extAccountDetails.bankName : 'Link Bank Account'}
							{extAccountDetails.status
								? ` (ending in ...${extAccountDetails.accountNo.substring(
										extAccountDetails.accountNo.length - 4
								  )})`
								: ''}
						</div>
						{!extAccountDetails.status ? (
							<div>
								<Button
									type={ButtonTypes.TERTIARY}
									size={ButtonSizes.COMPACT}
									onClick={() => {
										setLinkThroughEveryday(false);
										openPlaid();
									}}
								>
									Link
								</Button>
							</div>
						) : (
							<></>
						)}
					</div>
				),
				value: 'EXTERNAL'
			},
			{
				label: (
					<div>
						Everyday Account{' '}
						{everydayInfo ? `(Available balance: $${getDecimalVal(Number(everydayInfo), 2)})` : ''}
					</div>
				),
				value: 'EVERYDAY'
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[extAccountDetails.status, extAccountDetails.accountNo, everydayInfo]
	);

	const getExpenseDetails = useCallback(
		async (expenseId: string) => {
			try {
				const response = await expenseApi.getExpense(expenseId);
				//const paidFromAccounts = response.data.account_breakdown.payment_details.paid_from_accounts;
				//const hsaAmount = paidFromAccounts.filter((item: any) => item.account === 'HSA')[0].amount;
				//const inEligibleAmount = response.data.expense_summary.ineligible_amount;
				//let mistakenAmount = hsaAmount;
				// if (inEligibleAmount != 0 && inEligibleAmount <= hsaAmount) {
				// 	mistakenAmount = inEligibleAmount;
				// }
				const taxYearDetails = response.data.expense_details.transaction_date;
				const mistakenDistData = {
					amount: response.data.fix_mistaken_withdrawal_amount,
					taxYear: new Date(taxYearDetails * 1000).getFullYear().toString()
				};
				setMistakenDist(mistakenDistData);
			} catch (error) {
				navigate('/apps/expenses/summary');
			}
		},
		[navigate]
	);

	const getEverydayBalance = useCallback(async () => {
		try {
			const response = await dashboardApi.getAccountDetail(AccountType.EVERYDAY);
			setEverydayInfo(response.data.balance + '');
		} catch (error) {
			console.error(error);
		}
		try {
			const bankAccountDetails = (await plaidApi.getBankAccountDetails()).data?.body?.data?.[0];
			if (bankAccountDetails) {
				setExtAccountDetails({
					status: bankAccountDetails.link_status.ext_bank_account,
					accountId: bankAccountDetails.ext_account_id + '',
					accountNo: bankAccountDetails.ext_account_number + '',
					bankName: bankAccountDetails.institution_name
				});
			}
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			await getEverydayBalance();
			const expenseId = searchParams.get('id');
			if (expenseId) {
				await getExpenseDetails(expenseId);
			}
			setIsLoading(false);
		};
		fetchData();
	}, [getEverydayBalance, getExpenseDetails, searchParams]);

	useEffect(() => {
		if (extAccountDetails.accountNo || everydayInfo) {
			const newSelectedAccount = extAccountDetails.status ? accountOptions[0] : accountOptions[1];
			setSelectedAccount(newSelectedAccount);
		}
	}, [extAccountDetails, everydayInfo, accountOptions]);

	useEffect(() => {
		if (networkResponse) {
			openModal('accountLinkSuccessModal');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkResponse]);

	useEffect(() => {
		if (networkError && networkError.response.data.error.code == 'NAME_DOES_NOT_MATCH') {
			openModal('invalidAccountModal');
		}
	}, [networkError]);

	if (isLoading || networkLoading) {
		return <Loader />;
	}

	const handleFundEveryday = () => {
		if (extAccountDetails.status) {
			openModal('makeTransferModal');
		} else {
			setLinkThroughEveryday(true);
			openPlaid();
		}
	};

	const handleSubmitCorrection = () => {
		setIsLoading(true);
		transactionsApi
			.fixMistakenWithdrawal({
				refund_from: selectedAccount?.value,
				expense_id: searchParams.get('id')
			})
			.then(() => {
				setIsSubmissionComplete(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<Modal
				isOpen={modalVisible.makeTransferModal}
				style={customModalStyles}
				onRequestClose={() => {
					openModal('makeTransferModal');
				}}
				contentLabel="Make Transfer Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<MakeTransfer
						closeModal={() => {
							closeModal('makeTransferModal');
						}}
						closeWithRefresh={() => {
							setIsLoading(true);
							closeModal('makeTransferModal');
							navigate('/apps/everyday/dashboard');
						}}
						info={{
							accountId: extAccountDetails.accountId,
							bankName: extAccountDetails.bankName,
							bankAccountNumber: extAccountDetails.accountNo || '',
							everydayAccountNumber: '',
							everydayAccountBalance: everydayInfo
						}}
					/>
				</div>
			</Modal>
			<Modal
				isOpen={modalVisible.invalidAccountModal}
				style={customModalStyles}
				onRequestClose={() => {
					openModal('invalidAccountModal');
				}}
				contentLabel="Link Error Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div className="">
					<div className="modal-header">
						<div
							onClick={() => {
								closeModal('invalidAccountModal');
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-white text-center mt-32 text-h3-325`}>Connect to Your Bank</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={linkBankAccountError} width="180px"></img>

						<div className="py-32">
							<p className="text-h2-325 text-center text-primary">Uh oh!</p>

							<p className={`px-64 py-16 text-base-325 text-primary`}>
								The name on the bank account you are trying to link does not match the name on your
								InComm Benefits account.
							</p>
							<p className={`px-64 pt-4 text-base-325 text-primary`}>
								For security purposes we aren’t able to link accounts unless account names match
								exactly.
							</p>
						</div>

						<div className={`d-flex flex-row flex mt-32 gap-20`}>
							<Button
								type={ButtonTypes.PRIMARY}
								onClick={() => {
									closeModal('invalidAccountModal');
								}}
							>
								TRY ANOTHER ACCOUNT
							</Button>
						</div>
						<p className={'pt-8 text-primary'}>
							<span className="text-base-325 px-4">Need Help?</span>
							<StyledLink
								type={StyledLinkTypes.PRIMARY}
								onClick={() => {
									openModal('contactUsModal');
								}}
							>
								Contact Us
							</StyledLink>
						</p>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={modalVisible.accountLinkSuccessModal}
				style={customModalStyles}
				onRequestClose={() => {
					closeModal('accountLinkSuccessModal');
				}}
				contentLabel="Link Success Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={async () => {
								closeModal('accountLinkSuccessModal');
								if (linkThroughEveryday) navigate(`/apps/expense?id=${searchParams.get('id')}`);
								else {
									setIsLoading(true);
									await getEverydayBalance().then(() => {
										setIsLoading(false);
									});
								}
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h3-325 text-white text-center pt-32`}>Success!</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={ConfirmationImage} width="180px"></img>
						<p className={`px-64 py-16 text-primary text-base-325`}>
							Your bank account is now linked to Incomm Benefits! Now we need you to fund your
							spending account in order to fund your regular purchases.
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								type={ButtonTypes.TERTIARY}
								onClick={async () => {
									closeModal('accountLinkSuccessModal');
									if (linkThroughEveryday) navigate(`/apps/expense?id=${searchParams.get('id')}`);
									else {
										setIsLoading(true);
										await getEverydayBalance().then(() => {
											setIsLoading(false);
										});
									}
								}}
							>
								Continue
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<ContactUs
				openModal={modalVisible.contactUsModal}
				closeModal={() => {
					closeModal('contactUsModal');
				}}
				eventPage="Dashboard - Account Linking - Name Mismatch"
			/>
			{isSubmissionComplete ? (
				<FixWithdrawalSubmissionComplete
					id={searchParams.get('id') || ''}
					taxYear={mistakenDist.taxYear}
					selectedAccount={selectedAccount?.value}
					mistakenAmount={mistakenDist?.amount}
				/>
			) : (
				<>
					<div className={`d-flex justify-content-between align-items-center mx-auto mt-128`}>
						<p className="text-h3-325 text-white">Fix Mistaken Withdrawal</p>
						<StyledLink
							type={StyledLinkTypes.TERTIARY}
							onClick={() => {
								navigate(`/apps/expense?id=${searchParams.get('id')}`);
							}}
						>
							Cancel
						</StyledLink>
					</div>
					<div className={classes['fix-withdrawal-wrapper']}>
						<div className="text-base-325 d-flex flex-column mt-8">
							<p>
								If this transaction was not an eligible expense, you can transfer funds back to your
								HSA as a mistaken withdrawal. This will appear on your 1099-SA form for the tax year
								noted below.
							</p>
							<div className="d-flex justify-content-between my-16">
								<p>Amount of mistaken distribution:</p>
								<span>${getDecimalVal(Number(mistakenDist?.amount), 2)}</span>
							</div>
							<div className="d-flex justify-content-between">
								<p>Tax year of mistaken distribution:</p>
								<span>{mistakenDist?.taxYear}</span>
							</div>
						</div>
						<div className="my-32">
							<p>From</p>
							<Select
								options={accountOptions}
								isSearchable={true}
								placeholder={'From'}
								value={selectedAccount}
								onChange={option => {
									setSelectedAccount(option as IOption);
								}}
								styles={{
									control: getSelectStyle,
									singleValue: getSingleValueStyle
								}}
								components={{ Option: CustomOption }}
							/>
							{selectedAccount?.value === 'EVERYDAY' &&
							Number(mistakenDist?.amount) > Number(everydayInfo) ? (
								<div className={`d-flex flex-column my-16 ${classes['btn-wrapper']}`}>
									<div>
										<Banner type={BannerTypes.WARNING} icon={Warning} fill={true}>
											<div className={`d-flex align-self-center text-sm-325 text-primary`}>
												Your Everyday Account balance does not have enough to cover the full
												mistaken distribution amount.
											</div>
										</Banner>
									</div>
									<div className="d-flex mt-8">
										<Button type={ButtonTypes.SECONDARY} onClick={handleFundEveryday}>
											Fund Everyday Account
										</Button>
									</div>
								</div>
							) : (
								''
							)}
							<p className="mt-16">To</p>
							{/* <hr /> */}
							<Select
								options={[
									{
										label: `HSA`,
										value: 'HSA'
									}
								]}
								className="mb-40"
								isSearchable={true}
								placeholder={'Select the account to transfer funds from'}
								value={{
									label: `HSA`,
									value: 'HSA'
								}}
								styles={{
									control: getSelectStyle,
									singleValue: getSingleValueStyle
								}}
								isDisabled
							/>
							<hr />
						</div>
						<div className="text-sm-325 my-32">
							By clicking ‘submit’, I certify that the above distribution was the result of a
							mistake and I authorize InComm Benefits to repay this contribution as a mistaken
							distribution. I understand that InComm Benefits is not required to accept this
							repayment and that I am responsible for any tax consequences that may result.
						</div>
						<div className={`d-flex flex-column ${classes['btn-wrapper']}`}>
							<Button
								onClick={handleSubmitCorrection}
								isDisabled={
									(selectedAccount?.value === ReimburseTo.EVERYDAY &&
										everydayInfo < mistakenDist?.amount) ||
									(selectedAccount?.value === ReimburseTo.EXTERNAL && !extAccountDetails.status)
								}
							>
								Submit Correction
							</Button>
							<Button
								type={ButtonTypes.SECONDARY}
								onClick={() => {
									navigate(`/apps/expense?id=${searchParams.get('id')}`);
								}}
							>
								Back
							</Button>
						</div>
					</div>
				</>
			)}
		</>
	);
};
