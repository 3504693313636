import React, { useState } from 'react';
import classes from './MakeTransfer.module.scss';
import { Button, ButtonTypes } from '../common/Button';
import Select from 'react-select';
import { FormControl } from '../../components/common/FormControl';
import { useForm, Controller } from 'react-hook-form';
import IHLogo from '../../assets/img/IHLogoDark.svg';
import confirmationImg from '../../assets/img/ThumbsUpLight.svg';
import { Input, InputTypes } from '../common/Input';
import { investmentApi } from '../../services/investmentService';
import { getDecimalVal } from '../../utils/numberUtil';
import { Banner, BannerTypes } from '../common/Banner';
import { Incomplete, Warning } from '../icons';
import { Loader } from '../../components/common/Loader';

import { getSelectStyle, getSingleValueStyle } from '../../constants/common';
import { ControlType } from '../common/FormControl/FormControl.constants';

enum FundType {
	BANK = 'BANK',
	EVERYDAY = 'EVERYDAY'
}
type TransactionType = 'C' | 'D';

export const MakeTransfer = ({
	info,
	closeModal,
	closeWithRefresh = () => {}
}: {
	closeModal: () => void;
	closeWithRefresh: () => void;
	info: any;
}) => {
	const [transferState, setTransferState] = useState<'transfer' | 'confirmation' | 'success'>(
		'transfer'
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [transferFundDetail, setTransferFundDetail] = useState<{
		amount: number;
		ext_account_id: string;
		txn_type: TransactionType;
	}>({
		amount: 0,
		ext_account_id: '',
		txn_type: 'C'
	});

	const [transactionError, setTransactionError] = useState<{
		message: string;
		reason?: string;
	} | null>(null);

	const transferFromOptions = [
		{
			label: `Everyday Account (Available balance ${getDecimalVal(info.everydayAccountBalance)})`,
			value: FundType.EVERYDAY
		},
		{
			label: `${info.bankName}....${info.bankAccountNumber.slice(-4)}`,
			value: FundType.BANK
		}
	];
	const transferToOptions = [
		{ label: 'Everyday Account', value: FundType.EVERYDAY },
		{
			label: `${info.bankName}....${info.bankAccountNumber.slice(-4)}`,
			value: FundType.BANK
		}
	];
	const getTransferFromLabel = (value: string) => {
		return transferFromOptions.find(option => option.value === value)?.label;
	};
	const getTransferToLabel = (value: string) => {
		return transferToOptions.find(option => option.value === value)?.label;
	};

	const {
		//register,
		control,
		setValue,
		getValues,
		handleSubmit,
		formState: { isValid }
	} = useForm<{ from: string; to: string; amount: string }>({
		mode: 'onChange',
		defaultValues: {
			from: '',
			to: '',
			amount: '0.00'
		}
	});

	const transferFund = () => {
		setIsLoading(true);
		investmentApi
			.loadMoney({
				reload_type: 'OneTime',
				...transferFundDetail
			})
			.then(() => {
				setIsLoading(false);
				setTransferState('success');
			})
			.catch((error: any) => {
				setIsLoading(false);
				if (error?.response?.data?.error?.message == 'CHECK EVERYDAY BALANCE') {
					setTransactionError({
						message: 'The amount you entered is over the available balance in your account.'
					});
				} else {
					setTransactionError(error.response.data.error);
				}
				setTransferState('transfer');
			});
	};
	const onSubmit = handleSubmit((data: any) => {
		if (+data.amount < 10) {
			setTransactionError({
				message: 'The amount you entered is below the transfer limit of $10.00.'
			});
			return;
		} else if (
			data.from == FundType.EVERYDAY &&
			data.to == FundType.BANK &&
			+data.amount > info.everydayAccountBalance
		) {
			setTransactionError({
				message: 'The amount you entered is over the available balance in your account.'
			});
			return;
		} else if (+data.amount > 5000) {
			setTransactionError({
				message: 'The amount you entered is more than the maximum daily limit of $5,000.00.'
			});
			return;
		} else {
			setTransactionError(null);
		}

		setTransferFundDetail({
			amount: +data.amount,
			ext_account_id: info.accountId,
			txn_type: data.from == FundType.EVERYDAY && data.to == FundType.BANK ? 'C' : 'D'
		});
		setTransferState('confirmation');
	});
	const transferFundConfirmation = () => {
		return (
			<React.Fragment>
				<div className="modal-header">
					<div
						onClick={() => {
							closeModal();
						}}
						className="modal-close-btn-container"
					>
						<Incomplete></Incomplete>
					</div>
					<div className={`text-h3-325 mt-32 text-white text-center`}>Confirm Transfer</div>
				</div>
				<div className="modal-body d-flex flex-column align-items-center">
					<div className={`${classes['content-center']}`}>
						<img src={IHLogo} width="100" />
					</div>
					<br />

					<div className={`text-h5-700 text-primary text-center`}>
						You are transferring ${`${getDecimalVal(transferFundDetail.amount)}`}
						<br />
						From:{` `}
						{`${
							transferFundDetail.txn_type == 'C'
								? 'Your Everyday Account'
								: `${info.bankName}....${info.bankAccountNumber.slice(-4)}`
						}`}{' '}
						<br />
						To:{` `}
						{`${
							transferFundDetail.txn_type == 'C'
								? `${info.bankName}....${info.bankAccountNumber.slice(-4)}`
								: 'Your Everyday Account'
						}`}
					</div>
					<br />
					<div className={`text-sm-325 text-primary`}>
						Transfers can take 4-5 business days to complete. Once confirmed, your transfer can not
						be changed or canceled.
					</div>

					<div className={`${classes['content-center']} gap-20 pt-32`}>
						<Button
							onClick={() => {
								setTransferState('transfer');
							}}
							type={ButtonTypes.SECONDARY}
						>
							CANCEL
						</Button>
						<Button
							onClick={() => {
								transferFund();
							}}
							type={ButtonTypes.PRIMARY}
						>
							CONFIRM TRANSFER
						</Button>
					</div>
				</div>
			</React.Fragment>
		);
	};
	const transferFundSuccess = () => {
		return (
			<React.Fragment>
				<div className="modal-header">
					<div
						onClick={() => {
							closeWithRefresh();
						}}
						className="modal-close-btn-container"
					>
						<Incomplete></Incomplete>
					</div>
					<div className={`text-h3-325 mt-32 text-white text-center`}>Success</div>
				</div>
				<div className="modal-body d-flex flex-column align-items-center">
					<div className={`${classes['content-center']}`}>
						<img src={confirmationImg} width="200" />
					</div>
					<br />
					<div className={`text-base-325 text-primary`}>
						Your transfer is being processed. Please allow 4-5 business days for the transfer to
						complete.
					</div>

					<div className={`${classes['content-center']} pt-32`}>
						<Button
							type={ButtonTypes.PRIMARY}
							onClick={() => {
								closeWithRefresh();
							}}
						>
							OK
						</Button>
					</div>
				</div>
			</React.Fragment>
		);
	};

	const transferFundForm = () => {
		return (
			<div>
				<div className="modal-header">
					<div
						onClick={() => {
							closeModal();
						}}
						className="modal-close-btn-container"
					>
						<Incomplete></Incomplete>
					</div>
					<div className={`text-h3-325 mt-32 text-white text-center`}>Make a Transfer</div>
				</div>
				<div className="modal-body d-flex flex-column align-items-center">
					{transactionError?.message && (
						<div className={`pt-16 ${classes['w-80']}`}>
							<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
								<p className="text-sm-325">
									{transactionError?.reason || transactionError?.message}
								</p>
							</Banner>
						</div>
					)}

					<form onSubmit={onSubmit} className={`${classes.form} ${classes['w-80']}`}>
						<div className="">
							<Controller
								control={control}
								name="from"
								rules={{
									required: 'From is required.'
								}}
								render={({ field: { value }, fieldState: { error } }) => (
									<>
										<FormControl
											label={() => 'From'}
											error={() => error?.message || ''}
											controlType={ControlType.DARK}
										>
											<Select
												options={transferFromOptions}
												onChange={selected => {
													setValue('from', selected?.value || '');
													if (selected?.value == FundType.BANK) {
														setValue('to', FundType.EVERYDAY, { shouldValidate: true });
													} else if (selected?.value == FundType.EVERYDAY) {
														setValue('to', FundType.BANK, { shouldValidate: true });
													}
												}}
												isSearchable={true}
												placeholder={'Select the account to transfer funds from'}
												value={value ? { value, label: getTransferFromLabel(value) } : null}
												styles={{
													control: getSelectStyle,
													singleValue: getSingleValueStyle
												}}
											/>
										</FormControl>
									</>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name="to"
								rules={{
									required: 'To is required.'
								}}
								render={({ field: { value }, fieldState: { error } }) => (
									<>
										<FormControl
											label={() => 'To'}
											error={() => error?.message || ''}
											controlType={ControlType.DARK}
										>
											<Select
												options={transferToOptions.filter(
													option => option.value != getValues('from')
												)}
												onChange={selected => {
													setValue('to', selected?.value || '');
													if (selected?.value == FundType.BANK) {
														setValue('from', FundType.EVERYDAY, { shouldValidate: true });
													} else if (selected?.value == FundType.EVERYDAY) {
														setValue('from', FundType.BANK, { shouldValidate: true });
													}
												}}
												isSearchable={true}
												placeholder={'Select the account to transfer funds to'}
												value={value ? { value, label: getTransferToLabel(value) } : null}
												styles={{
													control: getSelectStyle,
													singleValue: getSingleValueStyle
												}}
											/>
										</FormControl>
									</>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name="amount"
								rules={{
									required: 'Amount is required.'
								}}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<>
										<FormControl
											label={() => 'Amount'}
											error={() => error?.message || ''}
											controlType={ControlType.DARK}
										>
											<Input
												onchange={evt => {
													const inputValue = evt.target.value;

													if (
														new RegExp(/^[0-9]*\.?[0-9]{0,2}$/).test(inputValue) &&
														Number(inputValue) < 10000
													) {
														evt.target.value = inputValue;
														onChange(evt);
													}
												}}
												onFocus={() => {
													if (Number(getValues('amount')) == 0) setValue('amount', '');
												}}
												onClear={() => {
													setValue('amount', '');
												}}
												isIncomplete={error ? true : false}
												isAmount={true}
												isClearable={true}
												type={InputTypes.TEXT}
												value={value}
												placeholder={'Amount'}
											/>
										</FormControl>
									</>
								)}
							/>
						</div>
						<div className={`${classes['button-group']} ${classes['content-center']} pt-24`}>
							<Button
								onClick={() => {
									// setTransferState('confirmation');
								}}
								isDisabled={!isValid}
								type={ButtonTypes.TERTIARY}
							>
								TRANSFER FUNDS
							</Button>
						</div>
					</form>
				</div>
			</div>
		);
	};

	const transferContent = () => {
		if (transferState == 'transfer') {
			return transferFundForm();
		} else if (transferState == 'success') {
			return transferFundSuccess();
		} else if (transferState == 'confirmation') {
			return transferFundConfirmation();
		}
	};
	if (isLoading) {
		return <Loader />;
	}
	return <div>{transferContent()}</div>;
};
