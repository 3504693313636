/* eslint-disable @typescript-eslint/dot-notation */
import classes from './Button.module.scss';
import { ButtonTypes, ButtonSizes, IconColor } from './Button.constants';
import React, { SVGProps } from 'react';
import { getClassNames } from '../../../utils/Css';

type ButtonProps = {
	type?: string;
	size?: string;
	isDisabled?: boolean;
	isFocused?: boolean;
	ltIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	rtIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	children?: JSX.Element | Array<JSX.Element> | string | string[];
	color?: string;
	wrapTextInOneLine?: boolean;
	otherClasses?: string;
	role?: string;
	name?: string;
};

export const Button = ({
	type,
	size,
	isDisabled,
	// isFocused,
	ltIcon: LtIcon,
	rtIcon: RtIcon,
	onFocus,
	onBlur,
	onClick,
	children,
	color,
	wrapTextInOneLine = false,
	otherClasses = '',
	role = 'button',
	name
}: ButtonProps) => {
	// style based on button type
	const getClassByBtnType = (btnType: string) => {
		let cssClass = '';
		switch (btnType) {
			case ButtonTypes.PRIMARY:
				cssClass = classes['btn-primary'];
				break;
			case ButtonTypes.SECONDARY:
				cssClass = classes['btn-secondary'];
				break;
			case ButtonTypes.TERTIARY:
				cssClass = classes['btn-tertiary'];
				break;
			case ButtonTypes.TRANSPARENT:
				cssClass = classes['btn-transparent'];
				break;
			default:
				break;
		}
		return cssClass;
	};

	// style based on button size
	const getBtnSize = (btnSize: string) => {
		let cssClass = '';
		switch (btnSize) {
			case ButtonSizes.COMPACT:
				cssClass = classes['compact'];
				break;
			case ButtonSizes.LARGE:
				cssClass = classes['large'];
				break;
			default:
				break;
		}
		return cssClass;
	};

	// Get all the classes of button by using util
	const classNames = getClassNames(
		getClassByBtnType(type || ButtonTypes.PRIMARY),
		getBtnSize(size || ButtonSizes.LARGE),
		isDisabled && classes.disabled,
		color == 'error' && classes.error,
		wrapTextInOneLine && classes['wrap-in-line'],
		otherClasses
	);

	//icon color based in button size and type
	const iconColor =
		type == ButtonTypes.SECONDARY && isDisabled
			? IconColor.DISABLED
			: ButtonTypes.SECONDARY == type
			? IconColor.SECONDARY
			: ButtonTypes.TERTIARY == type
			? IconColor.TERTIARY
			: IconColor.PRIMARY;

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (!isDisabled && onClick) {
			onClick(e);
		}
	};
	// Button JSX code snippet
	return (
		<button
			className={classNames}
			onFocus={onFocus}
			onBlur={onBlur}
			onClick={handleClick}
			role={role}
			name={name}
			aria-disabled={isDisabled}
		>
			{LtIcon && (
				<span className={classes['lt-icon']}>
					<LtIcon color={iconColor} width={20} height={20}></LtIcon>
				</span>
			)}
			{children}
			{RtIcon && (
				<span className={classes['rt-icon']}>
					{<RtIcon color={iconColor} width={20} height={20}></RtIcon>}
				</span>
			)}
		</button>
	);
};
