import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from '../containers/LoginPage';
import { SignupPage } from '../containers/SignupPage';
import { MaintenancePage } from '../containers/MaintenancePage';
import { AppLayout } from '../components/AppLayout';
// import { StyleGuidePage } from '../containers/StyleGuidePage';
import { DashboardPage as DashboardV2Page } from '../containers/DashboardV2Page';
import { CreateExpensePage } from '../containers/CreateExpensePage';
import { ExpenseListPage as ExpenseListV2Page } from '../containers/ExpenseListV2Page';

import { ExpenseDetailPage } from '../containers/ExpenseDetailPage';
import { ExpensePayBackPage } from '../containers/ExpensePayBackPage';

import { TransactionDetailPage } from '../containers/TransactionDetailPage';

import { ExpenseEditDocumentPage } from '../containers/ExpenseEditDocumentPage';

import { UserProfile } from '../containers/UserProfile';
import loadable from '@loadable/component';
import { AppFooter } from '../components/AppFooter';
import { ForgotPasswordPage } from '../containers/ForgotPasswordPage';
import FixMistakenWithdrawalPage from '../containers/FixMistakenWithdrawalPage';

const UpdateProfilePhonePage = loadable(() => import('../containers/UpdateProfilePhone'), {
	resolveComponent: (components: any) => components.UpdateProfilePhone
});

const ConnectBankAccountPage = loadable(() => import('../containers/ConnectBankAccountPage'), {
	resolveComponent: (components: any) => components.ConnectBankAccount
});

const ProfileEnableMFAPage = loadable(() => import('../containers/ProfileEnableMFA'), {
	resolveComponent: (components: any) => components.ProfileEnableMFA
});

const UpdateProfileEmailPage = loadable(() => import('../containers/UpdateProfileEmail'), {
	resolveComponent: (components: any) => components.UpdateProfileEmail
});

const UserProfileInfoPage = loadable(() => import('../containers/UserProfileInfo'), {
	resolveComponent: (components: any) => components.UserProfileInfo
});

const TransactionPage = loadable(() => import('../containers/TransactionPage'), {
	resolveComponent: (components: any) => components.TransactionPage
});

const DigitalCard = loadable(() => import('../containers/DigitalCard'), {
	resolveComponent: (components: any) => components.DigitalCard
});

const BeneficiaryList = loadable(() => import('../containers/BeneficiaryListPage'), {
	resolveComponent: (components: any) => components.BeneficiaryList
});
const HsaDdaDashboard = loadable(() => import('../containers/HsaDdaDashboardPage'), {
	resolveComponent: (components: any) => components.HsaDdaDashboardPage
});
const FsaDdaDashboard = loadable(() => import('../containers/FsaDashboardPage'), {
	resolveComponent: (components: any) => components.FsaDashboardPage
});

const CashbackRedeemPage = loadable(() => import('../containers/CashbackRedeemPage'), {
	resolveComponent: (components: any) => components.CashbackRedeemPage
});

const CashbackDetailsPage = loadable(() => import('../containers/CashbackDetailsPage'), {
	resolveComponent: (components: any) => components.CashbackDetailsPage
});

const CashbackTransactionDetailPage = loadable(
	() => import('../containers/CashbackTransactionDetailPage'),
	{
		resolveComponent: (components: any) => components.CashbackTransactionDetailPage
	}
);

const OpenInvestmentAccountPage = loadable(
	() => import('../containers/OpenInvestmentAccountPage'),
	{
		resolveComponent: (components: any) => components.OpenInvestmentAccountPage
	}
);

const StatementsPage = loadable(() => import('../containers/StatementsPage'), {
	resolveComponent: (components: any) => components.StatementsPage
});

const ContactUsPage = loadable(() => import('../containers/ContactUsPage'), {
	resolveComponent: (components: any) => components.ContactUsPage
});

export const AppRouter = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to="/login" replace />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/signup" element={<SignupPage />} />
				<Route path="/apps" element={<AppLayout />}>
					<Route path="dashboard" element={<DashboardV2Page />} />
					<Route path="hsa/dashboard" element={<HsaDdaDashboard key={'hsa'} />} />
					<Route path="everyday/dashboard" element={<HsaDdaDashboard key={'everyday'} />} />
					<Route path="fsa/dashboard" element={<FsaDdaDashboard key={'fsa'} />} />
					<Route path="lfsa/dashboard" element={<FsaDdaDashboard key={'lfsa'} />} />
					<Route path="dcfsa/dashboard" element={<FsaDdaDashboard key={'dcfsa'} />} />
					<Route path="linkaccount" element={<ConnectBankAccountPage />} />
					<Route path="transactions" element={<TransactionPage />} />
					<Route path="expenses/summary" element={<ExpenseListV2Page />} />
					<Route path="investment" element={<OpenInvestmentAccountPage />} />
					<Route path="mycards" element={<DigitalCard />} />
					<Route path="beneficiary/list" element={<BeneficiaryList />} />
					<Route path="expense" element={<ExpenseDetailPage />} />
					<Route path="cashback/redeem" element={<CashbackRedeemPage />} />
					<Route path="cashback/transaction/details" element={<CashbackTransactionDetailPage />} />
					<Route path="cashback/details" element={<CashbackDetailsPage />} />
					<Route path="expense/pay-back" element={<ExpensePayBackPage />} />
					<Route path="expense/attach-documents" element={<ExpenseEditDocumentPage />} />
					<Route path="expense/create" element={<CreateExpensePage />} />
					<Route path="expense/mistaken-withdrawal" element={<FixMistakenWithdrawalPage />} />
					<Route path="transaction" element={<TransactionDetailPage />} />
					<Route path="about" element={<h3>This is about page</h3>} />
					<Route path="profile" element={<UserProfile />} />
					<Route path="phone-verify" element={<UpdateProfilePhonePage />} />
					<Route path="email-verify" element={<UpdateProfileEmailPage />} />
					<Route path="personal" element={<UserProfileInfoPage />} />
					<Route path="mfa-setup" element={<ProfileEnableMFAPage />} />
					<Route path="statements/hsa" element={<StatementsPage key={'hsa'} />} />
					<Route path="statements/everyday" element={<StatementsPage key={'everyday'} />} />
					<Route path="statements/tax" element={<StatementsPage key={'tax'} />} />
					<Route path="contact" element={<ContactUsPage />} />
				</Route>
				<Route path="*" element={<h3>Page Not Found.</h3>} />
				{/* <Route path="/style" element={<StyleGuidePage />} /> */}
				<Route path="forgot" element={<ForgotPasswordPage />} />
				<Route path="maintenance" element={<MaintenancePage />} />
			</Routes>
			<AppFooter />
		</>
	);
};
