import React from 'react';
import classes from './MaintenancePage.module.scss';
// import { Header } from '../../components/Header';
import { ReactComponent as MaintenanceImage } from '../../assets/img/error-dark.svg';
import { StyledLink } from '../../components/common/Link';
import { StyledLinkTypes } from '../../components/common/Link/StyledLink.constants';
import { Message, Phone } from '../../components/icons';

export const MaintenancePage = () => {
	return (
		<React.Fragment>
			{/* <Header /> */}
			<div className={classes.page}>
				<div className={classes.content}>
					<div className="my-48">
						<MaintenanceImage width={500}></MaintenanceImage>
					</div>
					<p className="text-h2-325 text-yellow">Apologies</p>
					<p className="py-8 text-h4-325 text-white">We are currently doing system maintenance.</p>
					<p className="text-white text-base-325">Please check back January 1, 2025</p>
					<hr className="w-100 my-24" />
					<div className={`d-flex ${classes['gap-8']}`}>
						<p className="text-base-325 text-white d-flex justify-content-center align-items-center">
							Have questions?
						</p>
						<p className="p-8">
							<StyledLink
								type={StyledLinkTypes.TERTIARY}
								href="https://www.incomm.com/benefits/spending-account-faq/"
								isOpenInNewTab
							>
								Visit our FAQs
							</StyledLink>
						</p>
					</div>
					<p className="text-base-325 text-white py-12">Or contact us:</p>
					<div
						className={`d-flex justify-content-center align-items-center p-8 mb-12 ${classes['gap-8']}`}
					>
						<span className="d-flex">
							<Message color={'#facc15'} width={18} height={18} />
						</span>
						<StyledLink
							type={StyledLinkTypes.TERTIARY}
							href="mailto:support@incommbenefits.com"
							isOpenInNewTab
						>
							support@incommbenefits.com
						</StyledLink>
					</div>

					<div
						className={`d-flex justify-content-center align-items-center p-8 ${classes['gap-8']}`}
					>
						<span className="d-flex">
							<Phone color={'#facc15'} width={18} height={18} />
						</span>
						<StyledLink type={StyledLinkTypes.TERTIARY}>855-936-3248</StyledLink>
					</div>

					<p className="text-sm-325 text-white">Mon-Fri 8AM-5PM PST</p>
				</div>
			</div>
		</React.Fragment>
	);
};
