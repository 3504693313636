import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useIdle } from 'react-use';
import { AppHeader } from '../AppHeaderV2';
import classes from './AppLayout.module.scss';
import { localStorageUtil } from '../../utils/localStorageUtil';
import UserPool from '../../utils/UserPool';
import cardSvg from '../../assets/img/card.svg';
import walletSvg from '../../assets/img/wallet.svg';
import hsaSvg from '../../assets/img/HSA.svg';
import investSvg from '../../assets/img/invest.svg';
import cashbackSvg from '../../assets/img/CashbackDark.svg';

export const AppLayout = () => {
	const navigate = useNavigate();
	const location = useLocation();
	// const isLoading = useRef<boolean>(true);

	const [isLoading, setIsLoading] = useState(true);
	const [svgImage, setSvgImage] = useState<null | string>(null);
	const autoLogoutTime: number = Number(process.env.REACT_APP_AUTO_LOGOUT_TIME_MINS) || 5;
	const isIdle = useIdle(autoLogoutTime * 60 * 1000);

	const backgroundData = useSelector((state: any) => state.backgroundData);

	const redirectUserOnLoginPage = (redirectTo = true) => {
		localStorageUtil.clearTokens();
		if (redirectTo) {
			navigate(`/login?redirectTo=${location.pathname}${location.search}`);
		} else {
			navigate(`/login`);
		}
		setIsLoading(false);
	};

	const signOutUser = () => {
		try {
			const cognitoUser: any = UserPool.getCurrentUser();
			cognitoUser.signOut();
		} catch (error) {}

		redirectUserOnLoginPage(false);
	};

	useEffect(() => {
		if (isIdle) {
			signOutUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIdle]);

	const checkAuth = async () => {
		if (
			!(
				location.pathname == '/login' ||
				location.pathname == '/signup' ||
				location.pathname == '/forgot'
			)
		) {
			// setIsLoading(true);
			try {
				const currentUser = UserPool.getCurrentUser();
				if (currentUser) {
					await currentUser?.getSession(async function (sessionError: any, session: any) {
						if (sessionError) {
							redirectUserOnLoginPage();
						}
						if (session.isValid()) {
							setIsLoading(false);
						} else {
							// Logout the user
							redirectUserOnLoginPage();
						}
					});
				} else {
					redirectUserOnLoginPage();
				}
			} catch (errorRes) {
				console.log('Error', errorRes);
				redirectUserOnLoginPage();
			}
		} else {
			setIsLoading(false);
		}
	};

	const getHeaderSvg = () => {
		let svg = null;

		switch (location.pathname) {
			case '/apps/profile':
			case '/apps/dashboard':
			case '/apps/expenses/summary':
				svg = cardSvg;
				break;
			case '/apps/everyday/dashboard':
				svg = walletSvg;
				break;
			case '/apps/hsa/dashboard':
				svg = hsaSvg;
				break;
			case '/apps/investment':
				svg = investSvg;
				break;
			case '/apps/cashback/details':
				svg = cashbackSvg;
				break;
		}

		setSvgImage(svg);
	};

	const isSmallerHeader = () => {
		return location.pathname == '/apps/expense/create';
	};

	// Call this function on every route changes
	useEffect(() => {
		checkAuth();
		getHeaderSvg();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	if (isLoading) {
		return (
			<div className={classes.container}>
				<></>
			</div>
		);
	} else {
		return (
			<section
				className={`${classes['section-100']} ${
					backgroundData.backgroundDark ? classes['background-dark'] : ''
				}`}
			>
				<AppHeader />
				<div
					className={` ${classes['bg-container']} 
					${isSmallerHeader() ? classes['bg-sm-container'] : ''} 
					${backgroundData.largeLayoutHeader ? classes['bg-lg-container'] : ''} `}
				>
					{svgImage && (
						<div className={classes['image-container']}>
							<img src={svgImage} className={classes.image} alt="" />
						</div>
					)}
				</div>
				{/* This is, the ARIA live region - displays the redux state message for live-region - is visually-hidden */}
				<div aria-live="polite" aria-atomic="true" className={classes['visually-hidden']}>
					{backgroundData.liveRegionData}
				</div>
				<div
					className={`${classes.container} ${isSmallerHeader() ? classes['sm-container'] : ''} ${
						backgroundData.largeLayoutHeader ? classes['lg-container'] : ''
					}`}
				>
					<Outlet />
				</div>
			</section>
		);
	}
};
