// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgPhone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 14 14"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M12.3 13.562h-.173a12.546 12.546 0 0 1-5.452-1.935A12.435 12.435 0 0 1 2.88 7.84 12.54 12.54 0 0 1 .945 2.372a1.725 1.725 0 0 1 .405-1.297 1.74 1.74 0 0 1 1.2-.63H4.5a1.77 1.77 0 0 1 1.748 1.5c.07.536.204 1.062.397 1.567A1.777 1.777 0 0 1 6.24 5.38l-.457.457A9 9 0 0 0 8.61 8.65l.458-.45a1.777 1.777 0 0 1 1.867-.405 7.179 7.179 0 0 0 1.567.397 1.762 1.762 0 0 1 1.5 1.785v1.8a1.77 1.77 0 0 1-1.762 1.77l.06.015Zm-7.8-12H2.708a.653.653 0 0 0-.608.698 11.378 11.378 0 0 0 1.733 4.965 11.25 11.25 0 0 0 3.442 3.442c1.498.968 3.2 1.574 4.973 1.77a.667.667 0 0 0 .502-.187.66.66 0 0 0 .188-.458V10a.645.645 0 0 0-.556-.653 8.566 8.566 0 0 1-1.814-.45.653.653 0 0 0-.683.143l-.75.75a.57.57 0 0 1-.675.09 10.147 10.147 0 0 1-3.832-3.825.555.555 0 0 1 .09-.675l.75-.75a.638.638 0 0 0 .142-.675 8.46 8.46 0 0 1-.45-1.815.653.653 0 0 0-.66-.578Z"
		/>
	</svg>
);
export default SvgPhone;
