// This is a helper function to generate the years list
export const getYearsList = (): number[] => {
	const currentYear = new Date().getFullYear();
	const currentCentury = Math.floor(currentYear / 100) * 100;
	const startYear = currentCentury - 100;
	const endYear = currentCentury + 100;

	const yearsList: number[] = [];
	for (let year = startYear; year <= endYear; year += 1) {
		yearsList.push(year);
	}

	return yearsList;
};

// List of months
export const MONTH_LIST = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
