/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './UserProfile.module.scss';

import { Loader } from '../../components/common/Loader';
import { Profile, Beneficiary, Card2, Bank } from '../../components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AccountType, AccountStatus, AccountPlanStatus } from '../../constants/common';
import { getAccountSummary } from '../../store/accountSummarySlice';
import IconNavLink from '../../components/common/IconNavLink';
import { ICON_NAV_LINK_TYPES } from '../../components/common/IconNavLink/IconNavLink.constants';
import { Helmet } from 'react-helmet';
import { isAccountClosed } from '../../utils/accountUtils';

export const UserProfile = () => {
	const [loading, setLoading] = useState(true);
	const [isOnlyDFSAccount, setIsOnlyDFSAccount] = useState<boolean>(true);
	const [isHsaAccountClosed, setIsHsaAccountClosed] = useState<boolean>(false);

	const dispatch = useDispatch<any>();
	const summaryData = useSelector((state: any) => state.summaryData);
	const isHSAAccountHolder = summaryData?.data?.hsa_summary?.filter(
		(account: any) =>
			account.type == AccountType.HSA && account.account_status == AccountStatus.ACTIVE
	).length;

	useEffect(() => {
		if (!summaryData.loading) {
			setLoading(false);
			const isDcfsaAccount = summaryData.data?.xfsa_summary.every(
				(account: { type: string }) => account.type == AccountType.DCFSA
			);
			if (
				isDcfsaAccount &&
				//dfsaAccount.plan_status == AccountPlanStatus.ACTIVE &&
				summaryData.data?.hsa_summary.length == 0
			) {
				setIsOnlyDFSAccount(true);
			} else {
				setIsOnlyDFSAccount(false);
			}
		} else {
			setLoading(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summaryData]);

	useEffect(() => {
		dispatch(getAccountSummary());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsHsaAccountClosed(isAccountClosed(summaryData.data));
	}, [summaryData.data]);

	const isTerminatedUser = () => {
		return [
			AccountPlanStatus.RUNOUT,
			AccountPlanStatus.READY_TO_CLOSE,
			AccountPlanStatus.PERM_CLOSED
		].includes(summaryData.data.TUserPlanStatus);
	};

	const isTerminatedUserPermClosed = () =>
		AccountPlanStatus.PERM_CLOSED == summaryData.data.TUserPlanStatus;

	return (
		<React.Fragment>
			<Helmet>
				<title>User Profile - InComm Benefits</title>
			</Helmet>
			<div className={`d-flex align-items-center ${classes['text-white']} mt-128`}>
				<Profile color="#fff" width={36} height={36} />
				<h3 className={`text-h3-325 d-flex align-items-center px-16`}>User Profile</h3>
			</div>

			{!loading && (
				<div className="d-flex flex-column mt-32">
					{!isOnlyDFSAccount && (
						<IconNavLink
							type={ICON_NAV_LINK_TYPES.LINK}
							href="/apps/mycards"
							leftIcon={
								<Card2
									color={isHsaAccountClosed || isTerminatedUser() ? '#d1d5db' : '#FAC300'}
									width={32}
									height={32}
									aria-hidden
								/>
							}
							title="My Benefits Card"
							isFirst
							hasRightArrow={!(isHsaAccountClosed || isTerminatedUser())}
							wrapperClasses={`${classes['zenda-benefit-card']}   ${
								isHsaAccountClosed || isTerminatedUser()
									? classes['disabled-card']
									: classes['zenda-blue-card']
							}`}
							arrowColor="white"
							isDisabled={isHsaAccountClosed || isTerminatedUser()}
						/>
					)}
					<IconNavLink
						type={ICON_NAV_LINK_TYPES.LINK}
						href="/apps/personal"
						leftIcon={<Profile color={'#111827'} width={32} height={32} aria-hidden />}
						title="Profile Information"
						mainSubtitle="Update your home address, login email, and phone number."
						wrapperClasses={classes['zenda-benefit-card']}
					/>
					{isHSAAccountHolder > 0 && (
						<IconNavLink
							type={ICON_NAV_LINK_TYPES.LINK}
							href="/apps/beneficiary/list"
							leftIcon={
								<Beneficiary
									color={isHsaAccountClosed ? '#d1d5db' : '#111827'}
									width={32}
									height={32}
									aria-hidden
								/>
							}
							hasRightArrow={!isHsaAccountClosed}
							title="Manage Beneficiaries"
							mainSubtitle="Manage Beneficiaries for your HSA."
							wrapperClasses={`${classes['zenda-benefit-card']} ${
								isHsaAccountClosed ? classes['disabled-card'] : ''
							}`}
							isDisabled={isHsaAccountClosed}
						/>
					)}
					<IconNavLink
						type={ICON_NAV_LINK_TYPES.LINK}
						href="/apps/linkaccount"
						leftIcon={
							<Bank
								color={isHsaAccountClosed || isTerminatedUserPermClosed() ? '#d1d5db' : ''}
								width={32}
								height={32}
							/>
						}
						title="Connected Accounts"
						mainSubtitle="Add or remove linked bank accounts."
						hasRightArrow={!(isHsaAccountClosed || isTerminatedUserPermClosed())}
						wrapperClasses={`${classes['zenda-benefit-card']} ${
							isHsaAccountClosed || isTerminatedUserPermClosed() ? classes['disabled-card'] : ''
						}`}
						isDisabled={isHsaAccountClosed || isTerminatedUserPermClosed()}
					/>
				</div>
			)}

			{loading && <Loader />}
		</React.Fragment>
	);
};
