import { ChevronClose } from '../../icons';
import { getYearsList, MONTH_LIST as months } from './CustomDatePickerHeader.helper';
import classes from './CustomDatePickerHeader.module.scss';

// CustomDatePickerHeader component for DatePicker from react-datepicker
// It has the same UI as the default react-datepicker header but adds more accessibility ARIA-controls to the header
// TODO: update the prop types - facing one type import issue
export const CustomDatePickerHeader = (props: any) => {
	const {
		date,
		datePickerId,
		changeYear,
		changeMonth,
		decreaseMonth,
		increaseMonth,
		prevMonthButtonDisabled,
		nextMonthButtonDisabled
	} = props;

	// get the years list
	const yearList = getYearsList();
	return (
		<div className={classes['date-picker-header']}>
			<div className={classes['date-picker-month-content']}>
				<button
					type="button"
					className={classes['date-picker-button']}
					onClick={decreaseMonth}
					disabled={prevMonthButtonDisabled}
					aria-label={
						date
							? `Previous Month Button, Current Month is: ${new Date(date).toLocaleString(
									'default',
									{
										month: 'long',
										year: 'numeric'
									}
							  )}`
							: ''
					}
				>
					<ChevronClose height={16} width={16} color="#9ca3af" />
				</button>
				<div
					id={`month-${datePickerId}`}
					className={classes['date-picker-month']}
					tabIndex={0}
					aria-label={`Selected Month is ${date.toLocaleDateString('en-US', {
						month: 'long',
						year: 'numeric'
					})}`}
				>
					{date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
				</div>
				<button
					type="button"
					className={classes['date-picker-button']}
					onClick={increaseMonth}
					disabled={nextMonthButtonDisabled}
					aria-label={
						date
							? `Next Month Button, Current Month is: ${new Date(date).toLocaleString('default', {
									month: 'long',
									year: 'numeric'
							  })}`
							: ''
					}
				>
					<ChevronClose height={16} width={16} color="#9ca3af" />
				</button>
			</div>
			<div className={classes['date-picker-selects']}>
				<select
					id={`month-select-${datePickerId}`}
					role="combobox"
					value={months[date.getMonth()]}
					onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
					aria-label="Select Month"
				>
					{months.map(option => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</select>
				<select
					id={`year-select-${datePickerId}`}
					role="combobox"
					value={date.getFullYear()}
					onChange={({ target: { value } }) => changeYear(value)}
					aria-label="Select Year"
				>
					{yearList.map(year => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};
