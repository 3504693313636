/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './ExpenseListPage.module.scss';
import Slider, { Settings } from 'react-slick';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ExpenseTable } from '../../components/common/ExpenseTable';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, ButtonSizes, ButtonTypes } from '../../components/common/Button';
import { Expense, Plus, Car, Incomplete, Setting, ChevronClose } from '../../components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountSummary } from '../../store/accountSummarySlice';
import { AccountPlanStatus, ExpenseGroupType } from '../../constants/common';

import { expenseApi } from '../../services/expenseService';
import { transactionsApi } from '../../services/transactionsService';
import { Loader } from '../../components/common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPaginationConfig } from '../../interface/common.interface';
import { groupExpense } from '../../utils/expenseUtils';
import { StyledLink, StyledLinkSizes } from '../../components/common/Link';
import drive from '../../assets/img/TravelLight.svg';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { Banner, BannerTypes } from '../../components/common/Banner';
import { isAccountClosed } from '../../utils/accountUtils';

type ExpenseGroup = {
	expense_group: string;
	label: string;
	count: number;
};

const CustomPrevArrow = (props: any) => {
	return (
		<>
			{!props.className?.includes('slick-disabled') && (
				<Button
					size={ButtonSizes.DEFAULT}
					type={ButtonTypes.TRANSPARENT}
					otherClasses="slick-custom-prev slick-arrow"
					onClick={props.onClick}
				>
					<ChevronClose className="arrows" color="yellow" height={32} width={32} />
				</Button>
			)}
		</>
	);
};
const CustomNextArrow = (props: any) => {
	return (
		<>
			{!props.className?.includes('slick-disabled') && (
				<Button
					size={ButtonSizes.DEFAULT}
					type={ButtonTypes.TRANSPARENT}
					onClick={props.onClick}
					otherClasses="slick-custom-next slick-arrow"
				>
					<ChevronClose className="arrows" color="yellow" height={32} width={32} />
				</Button>
			)}
		</>
	);
};

export const ExpenseListPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [isLearnMore, setLearnMore] = useState<boolean>(false);
	const [expenseGroup, setExpenseGroup] = useState<{
		total_expense_count: number;
		expense_groups_summary: Array<ExpenseGroup>;
	}>({
		total_expense_count: 10,
		expense_groups_summary: []
	});

	const [isExpenseListLoading, setIsExpenseListLoading] = useState(false);

	const [expenseList, setExpenseList] = useState<any>([]);

	const [searchParams, setSearchParams] = useSearchParams();

	const [selectedSummary, setSelectedSummary] = useState<{
		expense_group: string;
		label: string;
		count: number;
	} | null>(null);

	const [hasMoreRecords] = useState<boolean>(true);

	const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>({
		offset: 0,
		limit: 10
	});

	const [isHsaAccountClosed, setIsHsaAccountClosed] = useState<boolean>(false);

	const [slickSetting, setSlickSetting] = useState<Settings>({
		infinite: false,
		slidesToShow: 6,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 6
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 3.5
				}
			}
		]
	});

	const selectedExpenseGroupText = () => {
		switch (selectedSummary?.expense_group?.toLowerCase()) {
			case ExpenseGroupType.TAKE_ACTION:
				return 'These transactions require additional input before they can be fully processed.';
			case ExpenseGroupType.REIMBURSE:
				return 'Review these transactions to check if they may be eligible for reimbursement.';
			case ExpenseGroupType.DENIED:
				return 'These reimbursement requests have been reviewed by InComm Benefits support and deemed ineligible.';
			case ExpenseGroupType.AWAITING_REVIEW:
				return 'These expenses have been submitted to InComm benefits claim team and will take 1-2 business days from the date submitted for processing.';
			case ExpenseGroupType.TRAVEL_POTENTIAL:
				return 'Review your recent health expenses, create an expense for any travel costs and receive a reimbursement.';
			default:
				return '';
		}
	};

	const selectedExpenseGroupLink = () => {
		switch (selectedSummary?.expense_group?.toLowerCase()) {
			case ExpenseGroupType.TRAVEL_POTENTIAL:
				return (
					<StyledLink
						onClick={() => {
							setLearnMore(true);
						}}
						size={StyledLinkSizes.SM}
					>
						Learn More
					</StyledLink>
				);
			default:
				return '';
		}
	};

	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	const summaryData = useSelector((state: any) => state.summaryData);

	const ExpenseGroupOrder = {
		TAKE_ACTION: 1,
		TRAVEL_POTENTIAL: 2,
		REIMBURSE: 3,
		SETTLED: 4,
		AWAITING_REVIEW: 5,
		DENIED: 6,
		CANCELLED: 7
	};

	const isAddExpenseDisable = () => {
		return (
			summaryData.data?.hsa_summary.length == 0 &&
			summaryData.data?.xfsa_summary.reduce(
				(balanceSum: number, account: { balance: number; planned_balance_amount: number }) =>
					balanceSum + account.balance + (account?.planned_balance_amount || 0),
				0
			) == 0
		);
	};

	const isTerminatedUser = () => {
		return [AccountPlanStatus.READY_TO_CLOSE, AccountPlanStatus.PERM_CLOSED].includes(
			summaryData.data.TUserPlanStatus
		);
	};

	const getExpenseGroupSummary = () => {
		expenseApi
			.getExpenseGroupSummary()
			.then(response => {
				setIsLoading(false);
				const expenseGroupFiletedData = response.data.data.expense_groups_summary
					.filter((groupItem: ExpenseGroup) => !!groupItem.count)
					.map(
						(
							item: ExpenseGroup & {
								order: number;
							}
						) => {
							item.order = ExpenseGroupOrder[item.expense_group as keyof typeof ExpenseGroupOrder];
							if (
								![
									ExpenseGroupType.REIMBURSE,
									ExpenseGroupType.TAKE_ACTION,
									ExpenseGroupType.TRAVEL_POTENTIAL
								].includes(item.expense_group?.toLowerCase() as ExpenseGroupType)
							) {
								item.count = 0;
							}
							return item;
						}
					)
					.sort((a: { order: number }, b: { order: number }) => a.order - b.order);

				setExpenseGroup({
					total_expense_count: response.data.data.total_expense_count || 0,
					expense_groups_summary: expenseGroupFiletedData
				});
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const fetchNextData = () => {
		if (selectedSummary) {
			if (paginationConfig.offset > 0) {
				setIsLoadingMore(true);
			} else {
				setIsExpenseListLoading(true);
			}
			expenseApi
				.getExpenseList(
					selectedSummary.expense_group,
					paginationConfig.limit,
					paginationConfig.offset
				)
				.then((response: any) => {
					if (paginationConfig.offset == 0) {
						setExpenseList(response.data.data);
						setIsExpenseListLoading(false);
					} else {
						setExpenseList([...expenseList, ...response.data.data]);
						setIsLoadingMore(false);
					}
				})
				.catch(() => {
					setIsExpenseListLoading(false);
				});
		}
	};

	const dismissTransaction = (expenseId: string) => {
		setIsLoading(true);
		transactionsApi
			.dismissTransaction(expenseId, [
				{
					op: 'replace',
					path: '/travel_eligibility_status',
					value: 'TRAVEL_NA'
				}
			])
			.then(() => {
				setIsLoading(false);
				navigate(0);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const getDataLength = () => {
		return expenseList.length;
	};

	useEffect(() => {
		// (window as any).gtag('event', 'page_view', {
		// 	app_name: 'Zenda-web',
		// 	page_title: 'Health_Expenses',
		// 	send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
		// });

		getExpenseGroupSummary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getAccountSummary());
	}, [dispatch]);

	useEffect(() => {
		fetchNextData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationConfig]);

	useEffect(() => {
		if (selectedSummary) {
			setPaginationConfig({
				offset: 0,
				limit: 10
			});
		}
	}, [selectedSummary]);

	useEffect(() => {
		const expenseGroupType = expenseGroup.expense_groups_summary.find(
			item => item.expense_group == searchParams.get('type')
		);
		if (expenseGroupType) {
			setSelectedSummary(expenseGroupType);
		} else if (expenseGroup?.expense_groups_summary?.length) {
			setSelectedSummary(expenseGroup.expense_groups_summary[0]);
			setSearchParams({ type: expenseGroup.expense_groups_summary[0].expense_group });
		}

		setSlickSetting({
			infinite: false,
			slidesToShow:
				expenseGroup?.expense_groups_summary?.length < 7
					? expenseGroup?.expense_groups_summary?.length
					: 7,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow:
							expenseGroup?.expense_groups_summary?.length < 6
								? expenseGroup?.expense_groups_summary?.length
								: 6,
						slidesToScroll: 2,
						centerMode: false
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow:
							expenseGroup?.expense_groups_summary?.length < 5
								? expenseGroup?.expense_groups_summary?.length
								: 5,
						slidesToScroll: 2,
						centerMode: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow:
							expenseGroup?.expense_groups_summary?.length < 4
								? expenseGroup?.expense_groups_summary?.length
								: 4,
						slidesToScroll: 2,
						centerMode: false
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow:
							expenseGroup?.expense_groups_summary?.length < 2
								? expenseGroup?.expense_groups_summary?.length
								: 2,
						slidesToScroll: 1,
						centerMode: true,
						centerPadding: '100px'
					}
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow:
							expenseGroup?.expense_groups_summary?.length < 2
								? expenseGroup?.expense_groups_summary?.length
								: 2,
						slidesToScroll: 1,
						centerMode: true
					}
				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: true
					}
				}
			]
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expenseGroup]);

	useEffect(() => {
		setIsHsaAccountClosed(isAccountClosed(summaryData.data));
	}, [summaryData.data]);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '564px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none',
			backgroundColor: '#fff'
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Helmet>
				<title>Expenses - InComm Benefits</title>
			</Helmet>
			<Modal
				isOpen={isLearnMore}
				style={customStyles}
				onRequestClose={() => {
					setLearnMore(false);
				}}
				contentLabel="Learn More Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<React.Fragment>
					<div className="modal-header">
						<div
							onClick={() => {
								setLearnMore(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`d-flex justify-content-center text-h2-325 text-white mt-32`}>
							Did you know?
						</div>
					</div>
					<div
						className={` ${classes['background-blue']} modal-body d-flex flex-column align-items-center`}
					>
						<div className={`d-flex justify-content-center`}>
							<img src={drive} width="200" />
						</div>
						<br />
						<div className={`text-base-350 py-8 text-primary`}>
							The IRS allows you to use HSA/FSA dollars to pay for transportation costs for
							traveling to receive a health related service or product.
							<br /> <br /> You can include:
							<ul className={`text-sm-325`}>
								<li>Round trip mileage</li>
								<li>Ride share, taxi or public transportation costs</li>
								<li>Parking fees or tolls incurred</li>
								<li>
									Plane fare or hotel costs when the trip was primarily for, and essential to,
									medical care
								</li>
							</ul>
						</div>
						<br />

						<div className={` d-flex justify-content-center`}>
							<Button
								type={ButtonTypes.TERTIARY}
								onClick={() => {
									setLearnMore(false);
								}}
							>
								CONTINUE
							</Button>
						</div>
					</div>
				</React.Fragment>
			</Modal>
			<div className="mt-2">
				<h3 className={` d-flex align-items-center gap-20 ${classes['header-text']}`}>
					<Expense width={36} height={36} color="#fff" /> Expenses
				</h3>
				<div className="mt-16">
					<Button
						onClick={() => {
							navigate('/apps/expense/create');
						}}
						isDisabled={isAddExpenseDisable() || isTerminatedUser() || isHsaAccountClosed}
						ltIcon={Plus}
						size={ButtonSizes.COMPACT}
						type={ButtonTypes.TERTIARY}
					>
						Add Expense
					</Button>
				</div>
			</div>
			<div className={`d-flex flex-column justify-content-center`}>
				{!!expenseGroup.expense_groups_summary.length && (
					<div role="tablist">
						<Slider
							variableWidth
							centerPadding="0px"
							focusOnSelect
							prevArrow={<CustomPrevArrow />}
							nextArrow={<CustomNextArrow />}
							className="slider-wrapper"
							accessibility
							{...slickSetting}
						>
							{expenseGroup.expense_groups_summary.map((summary, index) => (
								<Button
									type={ButtonTypes.TRANSPARENT}
									size={ButtonSizes.DEFAULT}
									role="tab"
									key={`expense-summary-${index}`}
									onClick={() => {
										setSearchParams({ type: summary.expense_group });
										setSelectedSummary(summary);
									}}
									otherClasses={classes['tab-btn']}
								>
									<div
										className={`d-flex justify-content-center align-items-center ${classes['scroll-option']} `}
									>
										<p className="text-base-700">{summary.label}</p>
										{selectedSummary?.expense_group == summary.expense_group && (
											<div className={`${classes['selected-option']}`}></div>
										)}

										{!!summary.count && (
											<span
												className={`${classes['option-badge']} text-base-700`}
												aria-label={`, has count ${summary.count}`}
											>
												{summary.count}
											</span>
										)}
									</div>
								</Button>
							))}
						</Slider>
					</div>
				)}
				<br />

				{isHsaAccountClosed ? (
					<div className={` ${expenseGroup.expense_groups_summary.length ? '' : 'mt-40'} `}>
						<Banner type={BannerTypes.WARNING} icon={Setting} fill={true}>
							<div>
								<p className="text-base-700 my-2">This account is closed.</p>
								<p className="my-2">
									You are unable to update your expenses while using this account.
								</p>
							</div>
						</Banner>
					</div>
				) : (
					<div className="d-flex justify-content-between">
						<p className={`text-base-325 ${classes['gray-1000']}`}>{selectedExpenseGroupText()}</p>
						<p className={classes['para-link']}>{selectedExpenseGroupLink()}</p>
					</div>
				)}

				{isExpenseListLoading ? (
					<>
						<Loader />
					</>
				) : (
					<>
						<InfiniteScroll
							dataLength={getDataLength()}
							next={() => {
								setPaginationConfig({
									offset: paginationConfig.offset + 10,
									limit: 10
								});
							}}
							hasMore={hasMoreRecords}
							loader={<div>{isLoadingMore && <Loader />}</div>}
						>
							{selectedSummary?.expense_group == ExpenseGroupType.TRAVEL_POTENTIAL.toUpperCase() ? (
								<ExpenseTable
									expenses={groupExpense(JSON.parse(JSON.stringify(expenseList)))}
									hasAction={true}
									openExpenseIcon={false}
									dismissAction={{
										title: 'Dismiss',
										icon: <Incomplete width={16} height={16} color="#2a518c" />,
										action: dismissTransaction
									}}
									confirmAction={{
										title: 'Add Travel',
										icon: <Car width={22} height={22} color="#2a518c" />,
										action: expenseId => {
											navigate('/apps/expense/create', {
												state: { id: expenseId }
											});
										}
									}}
								/>
							) : (
								<ExpenseTable
									expenses={groupExpense(JSON.parse(JSON.stringify(expenseList)))}
									hasAction={false}
								/>
							)}
						</InfiniteScroll>

						{!expenseGroup.expense_groups_summary.length && (
							<div className={`mt-64 ${classes['activity-container']}`}>
								<div>
									<p className={classes['no-activity-text']}>
										Expenses for reimbursement will appear here when you use your debit card.
									</p>
								</div>
							</div>
						)}
					</>
				)}

				<br />
			</div>
		</>
	);
};
